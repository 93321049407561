import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class FeeModel extends BaseModel {
  constructor(name = '', description = '', amount = 0, slots = [], deletedAt = null, id = null) {
    super()
    this.name = name
    this.description = description
    this.amount = amount
    this.slots = slots
    this.deletedAt = deletedAt
    this.id = id
  }

  toFirestoreJSON() {
    return { id: this.id, name: this.name, amount: this.amount }
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    amount: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1)
      .label('Amount')
      .required(),
  })
}

export default FeeModel
