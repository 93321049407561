import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, Hidden, List, ListItem, ListItemText } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { useAuth } from 'hooks/useAuth'
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js'
import Brand from './Brand'
import styles from 'assets/jss/material-dashboard-react/components/sidebarStyle.js'

const useStyles = makeStyles(styles)

export default function Sidebar(props) {
  const classes = useStyles()
  const { color, routes = [], uri } = props
  const [, , idToken] = useAuth()

  const permissionRoutes = routes.filter(
    (r) => idToken?.claims.permissions && idToken?.claims.permissions.includes(r.permission)
  )

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) =>
    uri
      ?.split('/', 3)
      .filter((p) => p)
      .join('/')
      .endsWith(
        routeName
          .split('/', 3)
          .filter((p) => p)
          .join('/')
      )
      ? true
      : false

  const links = (
    <List className={classes.list}>
      {permissionRoutes.map((prop, key) => {
        const act = activeRoute(prop.path)

        const listItemClasses = classNames({
          [' ' + classes[color]]: act,
        })

        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: act,
        })

        return (
          <Link to={prop.path} className={classes.item} key={key}>
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === 'string' ? (
                <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} />
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography
              />
            </ListItem>
          </Link>
        )
      })}
    </List>
  )

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Brand classes={classes} />
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          <div className={classes.background} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <Brand classes={classes} />
          <div className={classes.sidebarWrapper}>{links}</div>
          <div className={classes.background} />
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  image: PropTypes.string,
  logoText: PropTypes.string,
  open: PropTypes.bool,
}
