import React, { useState, memo } from 'react'
import DataGrid from 'components/DataGrid'
import { getAllTemplates, deleteTemplate } from 'actions/template'
import columns from './columns'
import { navigate } from 'gatsby'

const TemplateListing = memo(() => {
  const [reload, setReload] = useState(false)
  const fetchData = async (pageInfo, limit) => getAllTemplates({ pageInfo, limit })

  const onAdd = () => {
    navigate('/admin/templates/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/templates/edit/${id}`)
  }

  const onDelete = (template) => {
    deleteTemplate(template.id).then(() => {
      setReload(true)
    })
  }

  const isDeletable = (template) => template.settings.length < 1 && template.reminders.length < 1

  return (
    <DataGrid
      columns={columns}
      fetchData={fetchData}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title="Templates"
      reload={reload}
      isDeletable={isDeletable}
    />
  )
})

export default TemplateListing
export { default as EditTemplate } from './Edit'
export { default as NewTemplate } from './New'
