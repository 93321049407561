import React, { memo, forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { exportBooking } from 'actions/booking'
import { CircularProgress } from '@material-ui/core'
import Form from './form'
import { LoadingProvider } from 'hooks/useLoading'

const ExportDialog = memo(
  forwardRef(({ date }, ref) => {
    const [open, setOpen] = useState(false)
    const [exporting, setExporting] = useState(false)

    const onClose = (e, r) => {
      if (r !== 'backdropClick') {
        setOpen(false)
      }
    }

    const onExport = async (data) => {
      if (!exporting) {
        try {
          setExporting(true)
          await exportBooking(data)
        } catch (e) {
          console.error(e.message)
        } finally {
          setExporting(false)
        }
      }
    }

    useImperativeHandle(ref, () => ({
      open: (params) => {
        setOpen(true)
      },
      close: () => onClose(),
    }))

    return (
      <Dialog open={!!open} onClose={onClose} aria-labelledby="alert-dialog-title" fullWidth>
        <DialogTitle id="alert-dialog-title">Export Booking</DialogTitle>
        <LoadingProvider>
          <Form onSubmit={onExport} date={date}>
            <DialogActions>
              <Button onClick={() => onClose()} color="primary">
                Close
              </Button>
              <Button type="submit" color="primary" disabled={exporting} autoFocus>
                {exporting ? <CircularProgress size={20} /> : 'Export'}
              </Button>
            </DialogActions>
          </Form>
        </LoadingProvider>
      </Dialog>
    )
  })
)

export default ExportDialog
