import { useState, useEffect } from 'react'
import { subDays, addDays } from 'date-fns'

const useStartEndDate = ([startDate, endDate]) => {
  const [dates, setDates] = useState({ minEndDate: null, maxStartDate: null })

  useEffect(() => {
    let minEndDate
    let maxStartDate

    if (startDate) {
      minEndDate = addDays(startDate, 1)
    }
    if (endDate) {
      maxStartDate = subDays(endDate, 1)
    }

    setDates({ minEndDate, maxStartDate })
  }, [startDate, endDate])

  return dates
}

export default useStartEndDate
