import React, { memo, lazy, Suspense } from 'react'
import Empty from 'components/Admin/Empty'

const Gateway = memo(({ gateway, config, ...props }) => {
  const Gateway = lazy(() => import(`./gateways/${gateway}/display`))

  return (
    <Suspense fallback={<Empty {...props} />}>
      <Gateway config={config} {...props} />
    </Suspense>
  )
})

export default Gateway
