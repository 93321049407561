import React, { useState, useEffect, forwardRef } from 'react'
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core'

export default forwardRef(({ InputLabelProps, onChange, value, error, variant = 'filled', ...props }, ref) => {
  const [checked, setChecked] = useState(value)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const handleChecked = (e) => {
    const checked = e.target.checked
    setChecked(checked)
    onChange(checked)
  }

  return (
    <FormControl variant={variant} error={error}>
      <FormControlLabel
        ref={ref}
        variant={variant}
        {...InputLabelProps}
        control={<Checkbox {...props} variant={variant} checked={checked} onChange={handleChecked} />}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  )
})
