import Role from './index'
import timestamp from 'utils/firebaseTimestamp'

const RoleModelConverter = {
  toFirestore: (role) => {
    const { id, ...fs } = role
    return { ...fs, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const role = snapshot.data(options)
    return new Role(role.name, role.description, role.permissions, role.users, role.deletedAt?.toDate(), snapshot.id)
  },
}
export default RoleModelConverter
