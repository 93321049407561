import {
  DashboardTwoTone,
  DescriptionTwoTone,
  PermIdentityTwoTone,
  WorkTwoTone,
  GroupTwoTone,
  SettingsTwoTone,
  ScheduleTwoTone,
  CalendarTodayTwoTone,
  AccessAlarmTwoTone,
  MonetizationOnTwoTone,
  PaymentTwoTone,
} from '@material-ui/icons'

import Dashboard from 'components/Admin/Dashboard'
import Setting from 'components/Admin/Setting'
import Booking, { NewBooking, EditBooking } from 'components/Admin/Booking'
import Slot, { NewSlot, EditSlot } from 'components/Admin/Slot'
import Fee, { NewFee, EditFee } from 'components/Admin/Fee'
import Time, { NewTime, EditTime } from 'components/Admin/Time'
import Role, { NewRole, EditRole } from 'components/Admin/Role'
import Payment, { EditPayment } from 'components/Admin/Payment'
import User, { NewUser, EditUser } from 'components/Admin/User'
import Reminder, { NewReminder, EditReminder } from 'components/Admin/Reminder'
import Template, { NewTemplate, EditTemplate } from 'components/Admin/Template'

const prefix = '/admin'
const prefixedRoute = (route = '') => `${prefix}${route}`

const AdminRoutes = [
  {
    icon: DashboardTwoTone,
    name: 'Dashboard',
    path: prefixedRoute(''),
    component: Dashboard,
    permission: null,
  },
  {
    icon: CalendarTodayTwoTone,
    name: 'Bookings',
    path: prefixedRoute('/bookings'),
    component: Booking,
    permission: 'bookings',
    routes: [
      {
        path: prefixedRoute('/bookings/new/:slotTimetableId'),
        name: 'Create Booking',
        permission: 'bookings',
        component: NewBooking,
      },
      {
        path: prefixedRoute('/bookings/edit/:bookingId'),
        name: 'Edit Booking',
        permission: 'bookings',
        component: EditBooking,
      },
    ],
  },
  {
    icon: MonetizationOnTwoTone,
    name: 'Fees',
    path: prefixedRoute('/fees'),
    component: Fee,
    permission: 'fees',
    routes: [
      { path: prefixedRoute('/fees/new'), name: 'Create Fee', permission: 'fees', component: NewFee },
      { path: prefixedRoute('/fees/edit/:feeId'), name: 'Edit Fee', permission: 'fees', component: EditFee },
    ],
  },
  {
    icon: PaymentTwoTone,
    name: 'Payments',
    path: prefixedRoute('/payments'),
    component: Payment,
    permission: 'payments',
    routes: [
      {
        path: prefixedRoute('/payments/edit/:gateway'),
        name: 'Edit Payment',
        permission: 'payments',
        component: EditPayment,
      },
      // {
      //   path: prefixedRoute('/payments/edit/:paymentId'),
      //   name: 'Edit Payment',
      //   permission: 'payments',
      //   component: EditPayment,
      // },
    ],
  },
  {
    icon: WorkTwoTone,
    name: 'Slots',
    path: prefixedRoute('/slots'),
    component: Slot,
    permission: 'slots',
    routes: [
      { path: prefixedRoute('/slots/new'), name: 'Create Slot', permission: 'slots', component: NewSlot },
      { path: prefixedRoute('/slots/edit/:slotId'), name: 'Edit Slot', permission: 'slots', component: EditSlot },
    ],
  },
  {
    icon: ScheduleTwoTone,
    name: 'Times',
    path: prefixedRoute('/times'),
    component: Time,
    permission: 'times',
    routes: [
      { path: prefixedRoute('/times/new'), name: 'Create Time', permission: 'times', component: NewTime },
      { path: prefixedRoute('/times/edit/:timeId'), name: 'Edit Time', permission: 'times', component: EditTime },
    ],
  },
  {
    icon: AccessAlarmTwoTone,
    name: 'Reminders',
    path: prefixedRoute('/reminders'),
    component: Reminder,
    permission: 'reminders',
    routes: [
      {
        path: prefixedRoute('/reminders/new'),
        name: 'Create Reminder',
        permission: 'reminders',
        component: NewReminder,
      },
      {
        path: prefixedRoute('/reminders/edit/:reminderId'),
        name: 'Edit Reminder',
        permission: 'reminders',
        component: EditReminder,
      },
    ],
  },
  {
    icon: DescriptionTwoTone,
    name: 'Templates',
    path: prefixedRoute('/templates'),
    component: Template,
    permission: 'templates',
    routes: [
      {
        path: prefixedRoute('/templates/new'),
        name: 'Create Template',
        permission: 'templates',
        component: NewTemplate,
      },
      {
        path: prefixedRoute('/templates/edit/:templateId'),
        name: 'Edit Template',
        permission: 'templates',
        component: EditTemplate,
      },
    ],
  },
  {
    icon: PermIdentityTwoTone,
    name: 'Users',
    path: prefixedRoute('/users'),
    component: User,
    permission: 'users',
    routes: [
      { path: prefixedRoute('/users/new'), name: 'Create User', permission: 'users', component: NewUser },
      { path: prefixedRoute('/users/edit/:userId'), name: 'Edit User', permission: 'users', component: EditUser },
    ],
  },
  {
    icon: GroupTwoTone,
    name: 'Roles & Permissions',
    path: prefixedRoute('/roles'),
    component: Role,
    permission: 'roles',
    routes: [
      { path: prefixedRoute('/roles/new'), name: 'Create Roles & Permission', permission: 'roles', component: NewRole },
      {
        path: prefixedRoute('/roles/edit/:roleId'),
        name: 'Edit Roles & Permission',
        permission: 'roles',
        component: EditRole,
      },
    ],
  },
  {
    icon: SettingsTwoTone,
    name: 'Settings',
    path: prefixedRoute('/settings'),
    component: Setting,
    permission: 'settings',
  },
]
export default AdminRoutes
