import React from 'react'
import Form from '../Form'
import { getSlot, updateSlot } from 'actions/slot'
import { getAllTimes } from 'actions/time'
import { getAllFees } from 'actions/fee'
import useApi from 'hooks/useApi'

const AdminSlotEdit = ({ slotId }) => {
  const [slot, times, fees] = useApi(() => Promise.all([getSlot(slotId), getAllTimes(), getAllFees()]))

  const onSubmit = (data) => updateSlot(slotId, slot.set(data))

  return (
    <Form
      buttonText="Update"
      onSubmit={onSubmit}
      title="Edit Slot"
      defaultValues={slot}
      times={times?.data}
      fees={fees?.data}
      dirtyOnStart={false}
    />
  )
}
export default AdminSlotEdit
