import React, { memo, useState, useRef } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  Button,
} from '@material-ui/core'
import CustomToolbar from '../CustomToolbar'
import CustomNoRowsOverlay from './CustomNoRowsOverlay'
import useStyles from './styles'
import displayData from './displayData'
import ConfirmDialog from 'components/ConfirmDialog'

export default memo(
  ({
    columns,
    rows,
    onAdd,
    onEdit,
    onDelete,
    isDeletable,
    onPageChange,
    onRowsPerPageChange,
    lastItem,
    limit = 10,
    page = 0,
  }) => {
    const displayColumns = [{ field: 'action' }].concat(columns.filter((c) => !c.hide))
    const classes = useStyles()
    const [selectedRecord, setSelectedRecord] = useState(null)
    const deleteDialogRef = useRef(null)

    const onConfirmDelete = () => {
      deleteDialogRef.current.close()
      onDelete(selectedRecord)
    }

    const onDeleteRecord = (row) => {
      setSelectedRecord(row)
      deleteDialogRef.current.open()
    }

    isDeletable = isDeletable ?? (() => true)

    return (
      <TableContainer component={Paper}>
        <CustomToolbar onAdd={onAdd} />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {displayColumns.map((c) => (
                <TableCell key={c.field}>{c.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row) => (
                <TableRow key={row.id}>
                  {displayColumns.map((c) =>
                    c.field === 'action' ? (
                      <TableCell key={c.field} style={{ width: '14%' }}>
                        <Button onClick={() => onEdit(row.id)}>Edit</Button>
                        <Button onClick={() => onDeleteRecord(row)} disabled={!isDeletable(row)}>
                          Delete
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell key={c.field}>
                        {displayData(c, Array.isArray(c.field) ? c.field.map((field) => row[field]) : row[c.field])}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={displayColumns.length}>
                  <CustomNoRowsOverlay />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={!lastItem ? rows.length : -1}
                rowsPerPage={limit}
                colSpan={displayColumns.length}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                nextIconButtonProps={{ disabled: !lastItem }}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <ConfirmDialog
          title="Delete"
          confirmButtonText="Delete"
          text="Are you sure want to delete?"
          onConfirm={onConfirmDelete}
          ref={deleteDialogRef}
        />
      </TableContainer>
    )
  }
)
