import BaseModel from 'models/BaseModel'

class PaymentGatewayModel extends BaseModel {
  constructor(conf = null, deletedAt = null, id = null) {
    super()
    this.conf = conf
    this.deletedAt = deletedAt
    this.id = id
  }
}

export default PaymentGatewayModel
