import Template from './index'
import timestamp from 'utils/firebaseTimestamp'

const TemplateConverter = {
  toFirestore: (templateData) => {
    const { id, template, ...fs } = templateData
    const templateJson = JSON.stringify(template)
    return { template: templateJson, ...fs, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const template = snapshot.data(options)
    return new Template(
      template.name,
      template.type,
      template.subject,
      JSON.parse(template.template),
      template.settings,
      template.reminders,
      template.deletedAt?.toDate(),
      snapshot.id
    )
  },
}
export default TemplateConverter
