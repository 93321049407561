import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class RoleModel extends BaseModel {
  constructor(name = '', description = '', permissions = [], users = [], deletedAt = null, id = null) {
    super()
    this.name = name
    this.description = description
    this.permissions = permissions
    this.users = users
    this.deletedAt = deletedAt
    this.id = id
  }

  toFirestoreJSON() {
    return { name: this.name, id: this.id }
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    permissions: yup.array().label('Permissions').required(),
  })
}

export default RoleModel
