import React from 'react'
import { AddTwoTone as AddIcon, SearchTwoTone as SearchTwoToneIcon } from '@material-ui/icons'
import { Toolbar, IconButton, InputAdornment } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import useStyles from './styles'

const CustomToolbar = ({ onAdd }) => {
  const classes = useStyles()
  return (
    <Toolbar>
      <IconButton edge="start" aria-label="Add" onClick={onAdd}>
        <AddIcon />
      </IconButton>
      <div className={classes.title} />
      <CustomInput
        placeholder="Search"
        style={{ marginTop: '10px' }}
        inputProps={{ style: { height: '2em', paddingTop: 10 } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchTwoToneIcon />
            </InputAdornment>
          ),
        }}
      />
    </Toolbar>
  )
}

export default CustomToolbar
