import { useState, useEffect } from 'react'
import { useLoading } from './useLoading'

export default (fetchData, reload) => {
  const [pageInfo, setPageInfo] = useState({
    action: 0,
    page: 0,
    limit: 5,
  })
  const [data, setData] = useState({ data: [], lastItem: null })
  const [, setLoading] = useLoading()

  useEffect(() => {
    fetchData({ firstItem: data.data.shift(), lastItem: data.data.pop(), ...pageInfo }).then(data => {
      setData(data)
      setLoading(false)
    })
  }, [pageInfo, reload])

  return { ...data, pageInfo, setPageInfo }
}
