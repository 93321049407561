import User from './index'
import timestamp from 'utils/firebaseTimestamp'

const UserConverter = {
  toFirestore: (user) => {
    const { id, roles, slots, ...fs } = user
    return {
      ...fs,
      roles: roles.map((r) => (r.toFirestoreJSON !== undefined ? r.toFirestoreJSON() : r)),
      slots: slots.map((r) => (r.toReferenceJSON !== undefined ? r.toReferenceJSON() : r)),
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const user = snapshot.data(options)
    return new User(
      user.name,
      user.email,
      user.description,
      user.roles,
      user.slots,
      user.deletedAt?.toDate(),
      snapshot.id
    )
  },
}
export default UserConverter
