import React from 'react'
import Form from '../Form'
import { getFee, updateFee } from 'actions/fee'
import useApi from 'hooks/useApi'

const FeeEdit = ({ feeId }) => {
  const [fee] = useApi(() => Promise.all([getFee(feeId)]))

  const onSubmit = (data) => updateFee(feeId, fee.set(data))

  return <Form buttonText="Update" onSubmit={onSubmit} title="Edit Fee" defaultValues={fee} dirtyOnStart={false} />
}

export default FeeEdit
