import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultValues, validationSchema } from './formDetails'
import { DialogContent } from '@material-ui/core'
import CustomSelect from 'components/CustomSelect'
import { getAllSlots } from 'actions/slot'
import useApi from 'hooks/useApi'
import CustomAutocomplete from 'components/CustomAutocomplete'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import DatePicker from 'components/DatePicker'
import useStartEndDate from 'hooks/useStartEndDate'
import { formatDate } from 'utils/formatDateTime'
import { bookingStatuses } from 'constants/bookings'

const ExportForm = ({ date, onSubmit, children }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { ...defaultValues, startDate: date } })
  const slots = useApi(() => getAllSlots())
  const watchStartEndDate = watch(['startDate', 'endDate'])
  const { minEndDate, maxStartDate } = useStartEndDate(watchStartEndDate)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="slotId"
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  label="Slot"
                  error={errors.slotId}
                  options={slots?.data}
                  optionValue="id"
                  optionLabel="name"
                  blankItem
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <CustomAutocomplete
                  {...field}
                  label="Status"
                  error={errors.status}
                  options={bookingStatuses}
                  fullWidth
                  multiple
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="From Date"
                  inputProps={{ max: formatDate(maxStartDate) }}
                  error={errors.startDate}
                  fullWidth
                />
              )}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="To Date"
                  inputProps={{ min: formatDate(minEndDate) }}
                  error={errors.endDate}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      {children}
    </form>
  )
}

export default ExportForm
