import React, { useState, memo } from 'react'
import { navigate } from 'gatsby'
import DataGrid from 'components/DataGrid'
import columns from './columns'
import { getAllRoles, deleteRole } from 'actions/role'

const RoleListing = memo(() => {
  const [reload, setReload] = useState(false)
  const fetchData = async (pageInfo, limit) => getAllRoles({ pageInfo, limit })

  const onAdd = () => {
    navigate('/admin/roles/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/roles/edit/${id}`)
  }

  const onDelete = (role) => {
    deleteRole(role.id).then(() => {
      setReload(true)
    })
  }

  const isDeletable = (role) => role.users.length < 1

  return (
    <DataGrid
      columns={columns}
      fetchData={fetchData}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title="Roles"
      reload={reload}
      isDeletable={isDeletable}
    />
  )
})

export default RoleListing
export { default as EditRole } from './Edit'
export { default as NewRole } from './New'
