import React, { useState, memo } from 'react'
import { navigate } from 'gatsby'
import DataGrid from 'components/DataGrid'
import columns from './columns'
import { getAllTimes, deleteTime } from 'actions/time'

const TimeListing = memo(() => {
  const [reload, setReload] = useState(false)
  const fetchData = async (pageInfo, limit) => getAllTimes({ pageInfo, limit })

  const onAdd = () => {
    navigate('/admin/times/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/times/edit/${id}`)
  }

  const onDelete = (time) => {
    deleteTime(time.id).then(() => {
      setReload(true)
    })
  }

  const isDeletable = (time) => time.slots.length < 1

  return (
    <DataGrid
      columns={columns}
      fetchData={fetchData}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title="Times"
      reload={reload}
      isDeletable={isDeletable}
    />
  )
})

export default TimeListing
export { default as EditTime } from './Edit'
export { default as NewTime } from './New'
