import PaymentGateway from './index'
import timestamp from 'utils/firebaseTimestamp'

const PaymentGatewayConverter = {
  toFirestore: (doc) => {
    const { id, ...fs } = doc
    return { ...fs, ...timestamp() }
  },
  fromFirestore: (snapshot, options) => {
    const { conf, deletedAt } = snapshot.data(options)
    return new PaymentGateway(conf, deletedAt, snapshot.id)
  },
}

export default PaymentGatewayConverter
