import React, { memo, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import { format, parse, startOfWeek, getDay, isSameDay } from 'date-fns'
import Toolbar from './Toolbar'
import useApi from 'hooks/useApi'

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

export default memo(
  ({
    loadData,
    startTime,
    endTime,
    isPrivate,
    onSelectEvent,
    minDate,
    titleAccessor = 'name',
    resourceAccessor = 'slotId',
    eventPropGetter = null,
    step = 10,
    customButtons,
    logo,
  }) => {
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
    })

    const [day, setDay] = useState(new Date())
    const { timetables, slots } = useApi(() => loadData({ day, isPrivate }), [day], {
      timetables: [],
      slots: [],
    })

    const onChangeDate = (date) => {
      if (!isSameDay(day, date)) {
        setDay(date)
      }
    }

    const CustomToolbar = (props) => <Toolbar logo={logo} {...props} minDate={minDate} customButtons={customButtons} />

    return (
      <Calendar
        events={timetables}
        localizer={localizer}
        defaultView={Views.DAY}
        views={['day']}
        timeslots={1}
        min={startTime}
        max={endTime}
        step={step}
        resources={slots}
        resourceAccessor={resourceAccessor}
        titleAccessor={titleAccessor}
        resourceIdAccessor="id"
        resourceTitleAccessor="name"
        onSelectEvent={onSelectEvent}
        onNavigate={onChangeDate}
        eventPropGetter={eventPropGetter}
        minDate={minDate}
        components={{
          toolbar: CustomToolbar,
        }}
      />
    )
  }
)
