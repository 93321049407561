import React from 'react'
import { GridContainer, GridItem } from 'components/Grid'
import { Controller } from 'react-hook-form'
import CustomCheckbox from 'components/CustomCheckbox'
import CustomInput from 'components/CustomInput'

const AutoConfirmCancel = ({ classes, hookForm }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = hookForm
  const [autoConfirmBookings, autoCancelBookings] = watch(['autoConfirmBookings', 'autoCancelBookings'])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name="autoConfirmBookings"
            render={({ field }) => (
              <CustomCheckbox
                {...field}
                InputLabelProps={{
                  label: 'Auto Confirm Bookings',
                  labelPlacement: 'start',
                  className: classes.checkbox,
                }}
                error={errors?.autoConfirmBookings}
                disabled={autoCancelBookings}
              />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={3}>
          <Controller
            control={control}
            name="autoConfirmMinutes"
            render={({ field }) => (
              <CustomInput
                {...field}
                type="number"
                label="Minute(s) To Confirm After Booking Made"
                inputProps={{ min: 0 }}
                disabled={autoCancelBookings || !autoConfirmBookings}
                error={errors?.autoConfirmMinutes}
                fullWidth
              />
            )}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name="autoCancelBookings"
            render={({ field }) => (
              <CustomCheckbox
                {...field}
                InputLabelProps={{
                  label: 'Auto Cancel Un-Confirmed Bookings',
                  labelPlacement: 'start',
                  className: classes.checkbox,
                }}
                disabled={autoConfirmBookings}
                error={errors?.autoCancelBookings}
              />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={3}>
          <Controller
            control={control}
            name="autoCancelMinutes"
            render={({ field }) => (
              <CustomInput
                {...field}
                type="number"
                label="Minute(s) To Cancel After Booking Made"
                inputProps={{ min: 1 }}
                disabled={autoConfirmBookings || !autoCancelBookings}
                error={errors?.autoCancelMinutes}
                fullWidth
              />
            )}
          />
        </GridItem>
      </GridContainer>
    </>
  )
}

export default AutoConfirmCancel
