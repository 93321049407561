import Reminder from './index'
import timestamp from 'utils/firebaseTimestamp'

const ReminderConverter = {
  toFirestore: (reminder) => {
    const { id, ...fs } = reminder
    return { ...fs, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const reminder = snapshot.data(options)
    return new Reminder(
      reminder.name,
      reminder.description,
      reminder.type,
      reminder.bookingStatus,
      reminder.durationNumber,
      reminder.durationType,
      reminder.templateId,
      reminder.deletedAt?.toDate(),
      snapshot.id
    )
  },
}
export default ReminderConverter
