import Time from './index'
import timestamp from 'utils/firebaseTimestamp'

const TimeConverter = {
  toFirestore: (time) => {
    const { id, ...fs } = time
    return { ...fs, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const time = snapshot.data(options)
    return new Time(
      time.type,
      time.isPrivate,
      time.name,
      time.description,
      time.startDate.toDate(),
      time.endDate.toDate(),
      time.startTime.toDate(),
      time.endTime.toDate(),
      time.interval,
      time.days,
      time.slots,
      time.deletedAt,
      snapshot.id
    )
  },
}
export default TimeConverter
